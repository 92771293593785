'use client'

import { useEffect, useState } from 'react'

export function MockProvider({
  children,
}: Readonly<{
  children: React.ReactNode
}>) {
  const [mockingEnabled, enableMocking] = useState(false)

  useEffect(() => {
    async function enableApiMocking() {
      if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
        console.log('enableApiMocking')

        const { worker } = await import('../../mocks/browser')
        await worker.start({
          onUnhandledRequest: 'error',
          waitUntilReady: true,
        })
      }
      enableMocking(true)
    }

    enableApiMocking()
  }, [])

  if (!mockingEnabled) {
    return null
  }

  return <>{children}</>
}
