import type { ClassFormat as TClassFormat } from 'src/types/ObeClass'

export const defaults = {
  impressionTracker: {
    TRACK_LOGGED_IN_ONLY: true,
    THRESHOLD: 0.75,
  },
} as const

export const LocalStorageKeys = {
  HIDE_HOME_TOP_ROW: 'hide_home_top_row',
} as const

export const planLabelsByPeriod = {
  1: {
    term: 'Monthly',
    adjective: 'monthly',
    period: 'month',
  },
  3: {
    term: 'Quarterly',
    adjective: 'quarterly',
    period: 'quarter',
    giftCardValue: '$10',
  },
  12: {
    term: 'Annual',
    adjective: 'annually',
    period: 'year',
  },
} as const

export const freemium = {
  utmCampaignPrefix: 'freemium_',
} as const

export const SUB_PURCHASING_AS_RESUB = 'resub'
export const SUB_PURCHASING_REGULAR = 'membership'
export type SUB_PURCHASING_TYPES = 'resub' | 'membership'

export const ClassFormat = {
  LIVE: 'live',
  ON_DEMAND: 'on_demand',
  ENCORE: 'encore',
  AUDIO: 'audio',
  // We don't use `replay` anymore but we keep it here for backwards compatibility.
  // Users might still have replay class types in their class history.
  REPLAY: 'replay',
} as const satisfies Record<string, TClassFormat>

export const ProgramEnrollmentStatus = {
  ACTIVE: 'active',
  COMPLETED: 'complete',
  ENROLLED_IN_OTHER_PROGRAM: 'enrolled-in-other-program',
  NOT_STARTED: 'not-started',
  PAUSED: 'paused',
  STOPPED: 'stopped',
} as const

export const LegacyPlans = {
  QUARTERLY: 'standard_quarterly',
} as const
