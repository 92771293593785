import queryString from 'query-string'
import { storage } from 'src/storage'

export const storeImpactClickId = () => {
  try {
    const params = typeof window !== 'undefined' ? queryString.parse(window.location.search) : {}

    if (params?.irclickid) {
      storage.local.setItem(
        'affiliateReferrer',
        JSON.stringify({
          referrerId: params?.irclickid,
          referrerType: 'impactRadius',
        }),
      )
    }
  } catch (error) {
    return {}
  }
}
