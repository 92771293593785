import * as Sentry from '@sentry/nextjs'
import queryString from 'query-string'
import { freemium } from 'src/constants'
import { storage } from 'src/storage'

export const storeUtmPromoCodes = () => {
  if (typeof window !== 'undefined') {
    try {
      const pathname = window.location.pathname
      const params = queryString.parse(window.location.search)

      const utmMedium = params?.utm_medium?.toLowerCase?.()
      const shareSource = params?.source?.toLowerCase?.()

      if (params?.utm_campaign?.includes(freemium.utmCampaignPrefix)) {
        const partnershipSlug = params?.utm_campaign.replace(freemium.utmCampaignPrefix, '')
        storage.session.setItem('freemiumPartnershipSlug', partnershipSlug)
      }

      if (
        pathname.match(/\/videos\/.+/) &&
        params?.userId &&
        (shareSource === 'web' || shareSource === 'mobile')
      ) {
        const sharePromoCode = 'SHARE30'

        storage.session.setItem(obe.storage_keys.promotion_code, btoa(sharePromoCode))
      } else if (utmMedium === 'friend' && params?.utm_source) {
        storage.session.setItem(obe.storage_keys.promotion_code, btoa(params?.utm_source))

        if (params?.userId) {
          storage.session.setItem('userId', btoa(params?.userId))
        }

        if (params?.referralName) {
          storage.session.setItem('referralName', btoa(params?.referralName))
        }

        if (params?.returnUrl) {
          storage.session.setItem('returnUrl', params?.returnUrl)
        }
      } else {
        if (utmMedium === 'influencer' && params?.utm_source) {
          storage.session.setItem(obe.storage_keys.promotion_code, btoa(params?.utm_source))
        }

        if (params?.utm_content) {
          storage.session.setItem(obe.storage_keys.promotion_code, btoa(params?.utm_content))
        }

        if (params?.utm_promo) {
          storage.session.setItem(obe.storage_keys.promotion_code, btoa(params?.utm_promo))
        }
      }
    } catch (error) {
      Sentry.captureException(error, 'warning')
    }
  }
}
