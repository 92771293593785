import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.12_next@14.2.5_@babel+core@7.21.0_@playwright+test@1.46.0_babel-pl_3oqremf6kdjodqbbxypur7scii/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/swiper@11.1.14/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/swiper@11.1.14/node_modules/swiper/modules/navigation.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/swiper@11.1.14/node_modules/swiper/modules/pagination.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/swiper@11.1.14/node_modules/swiper/modules/scrollbar.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.21.0_@playwright+test@1.46.0_babel-plugin-macros@3.1.0_react-dom@18_67d3wagunyqjern6iohnwnovvq/node_modules/next/font/local/target.css?{\"path\":\"src/app/fontLoader.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"variable\":\"--font-centra-no2\",\"src\":[{\"path\":\"../../public/fonts/Centra/CentraNo2-Light.otf\",\"weight\":\"200\"},{\"path\":\"../../public/fonts/Centra/CentraNo2-LightItalic.otf\",\"weight\":\"200\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/Centra/CentraNo2-Book.otf\",\"weight\":\"300\"},{\"path\":\"../../public/fonts/Centra/CentraNo2-Medium.otf\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/Centra/CentraNo2-MediumItalic.otf\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/Centra/CentraNo2-Bold.otf\",\"weight\":\"600\"}]}],\"variableName\":\"centraFont\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.21.0_@playwright+test@1.46.0_babel-plugin-macros@3.1.0_react-dom@18_67d3wagunyqjern6iohnwnovvq/node_modules/next/font/local/target.css?{\"path\":\"src/app/fontLoader.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"variable\":\"--font-playfair-display\",\"src\":[{\"path\":\"../../public/fonts/Playfair_Display/PlayfairDisplay-Regular.ttf\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/Playfair_Display/PlayfairDisplay-Italic.ttf\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/Playfair_Display/PlayfairDisplay-Medium.ttf\",\"weight\":\"500\"},{\"path\":\"../../public/fonts/Playfair_Display/PlayfairDisplay-MediumItalic.ttf\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/Playfair_Display/PlayfairDisplay-SemiBold.ttf\",\"weight\":\"600\"},{\"path\":\"../../public/fonts/Playfair_Display/PlayfairDisplay-Bold.ttf\",\"weight\":\"700\"},{\"path\":\"../../public/fonts/Playfair_Display/PlayfairDisplay-BoldItalic.ttf\",\"weight\":\"700\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/Playfair_Display/PlayfairDisplay-ExtraBold.ttf\",\"weight\":\"800\"},{\"path\":\"../../public/fonts/Playfair_Display/PlayfairDisplay-ExtraBoldItalic.ttf\",\"weight\":\"800\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/Playfair_Display/PlayfairDisplay-Black.ttf\",\"weight\":\"900\"},{\"path\":\"../../public/fonts/Playfair_Display/PlayfairDisplay-BlackItalic.ttf\",\"weight\":\"900\",\"style\":\"italic\"}]}],\"variableName\":\"playfairDisplayFont\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/mockProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/application.scss");
